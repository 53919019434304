import { createRef, useContext, useImperativeHandle } from 'react';
import axios from 'axios';
import { NotifyContext } from '../contexts/NotifyContext';
import { loaderRef } from '../components/Loader';
import config from '../config';

export const notificationRef = createRef();

export function Notification() {
  const { Notify } = useContext(NotifyContext);
  const Show = (props, message, variant) => (Notify(props, message, variant));
  useImperativeHandle(notificationRef, () => ({
    Show,
  }), []);
  return null;
}

export const axiosCMSService = axios.create({
  baseURL: config.api.cms(),
});
axiosCMSService.defaults.headers.head['Content-Type'] = 'application/json';
axiosCMSService.defaults.validateStatus = (status) => status < 500;

axiosCMSService.interceptors.response.use(
  (response) => {
    // eslint-disable-next-line no-unused-expressions
    notificationRef.current?.Show(response?.data);
    loaderRef.current?.decLoader();

    return ({
      ...response,
      message: response?.data.message,
      error: response?.data.error,
      data: response?.data.data,
      statusCode: response?.data.statusCode,
    });
  },
  (error) => {
    console.log('Error', error);
    loaderRef.current?.decLoader();
    Promise.reject(error);
  },

  axiosCMSService.interceptors.request.use(
    (request) => {
      loaderRef.current?.incLoader();

      return request;
    },
  ),
);

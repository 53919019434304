import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import PaddingIcon from '@mui/icons-material/Padding';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ApprovalIcon from '@mui/icons-material/Approval';
import MenuIcon from '@mui/icons-material/Menu';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ShortTextIcon from '@mui/icons-material/ShortText';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Divider, List } from '@mui/material';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { DataContext } from '../contexts/DataContext';

export function DrawerList() {
  const { user } = React.useContext(AuthContext);
  const { setSelectedIndex, selectedIndex } = React.useContext(DataContext);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <List component="nav">
      {user && user.roleName === 'Admin' && (
        <>
          <ListSubheader>
            ADMİN
          </ListSubheader>
          <Link
            to="/admin"
            state={{ title: 'Admin Panel' }}
          >
            <ListItemButton
              selected={selectedIndex === 14}
              onClick={(event) => handleListItemClick(event, 14)}
            >
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Admin Panel" />
            </ListItemButton>
          </Link>
        </>
      )}
      <ListSubheader>
        ANASAYFA YÖNETİMİ
      </ListSubheader>
      <Link
        to="/slider"
        state={{ title: 'Slider' }}
      >
        <ListItemButton
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
        >
          <ListItemIcon>
            <WebStoriesIcon />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Slider" />
        </ListItemButton>
      </Link>
      {user && user.roleName === 'Admin' && (
        <>
          <Divider sx={{ my: 1 }} />
          <ListSubheader>
            ROL BAZLI YETKİLENDİRME
          </ListSubheader>
          <Link
            to="/userrole/userRole"
            state={{ title: 'Yetkilendirme' }}
          >
            <ListItemButton
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}
            >
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Yetkilendirme" />
            </ListItemButton>
          </Link>
        </>
      )}
      <Divider sx={{ my: 1 }} />
      <ListSubheader>
        SAYFA YÖNETİMİ
      </ListSubheader>
      <Link
        to="/page"
        state={{ title: 'Sayfalar' }}
      >
        <ListItemButton
          selected={selectedIndex === 13}
          onClick={(event) => handleListItemClick(event, 13)}
        >
          <ListItemIcon>
            <FileCopyIcon />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Sayfalar" />
        </ListItemButton>
      </Link>

      <Divider sx={{ my: 1 }} />
      <ListSubheader>
        MENÜ YÖNETİMİ
      </ListSubheader>
      <Link
        to="/menu"
        state={{ title: 'Menüler' }}
      >
        <ListItemButton
          selected={selectedIndex === 9}
          onClick={(event) => handleListItemClick(event, 9)}
        >
          <ListItemIcon>
            <MenuIcon />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Menü" />
        </ListItemButton>
      </Link>

      <Divider sx={{ my: 1 }} />
      <ListSubheader>
        DİĞER
      </ListSubheader>
      {user && user.roleName === 'Admin' && (
        <Link
          to="/approve/Approve"
          state={{ title: 'Onay Bekleyen İşlemler' }}
        >

          <ListItemButton
            selected={selectedIndex === 8}
            onClick={(event) => handleListItemClick(event, 8)}
          >
            <ListItemIcon>
              <ApprovalIcon />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Onay Bekleyen İşlemler" />
          </ListItemButton>
        </Link>
      )}
      <Link
        to="/linkshortener"
        state={{ title: 'Link Kısaltıcı' }}
      >
        <ListItemButton
          selected={selectedIndex === 11}
          onClick={(event) => handleListItemClick(event, 11)}
        >
          <ListItemIcon>
            <ShortTextIcon />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Link Kısaltıcı" />
        </ListItemButton>
      </Link>
      <Link
        to="/section/Section"
        state={{ title: 'Bölümler' }}
      >
        <ListItemButton
          selected={selectedIndex === 12}
          onClick={(event) => handleListItemClick(event, 12)}
        >
          <ListItemIcon>
            <PaddingIcon />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Bölümler" />
        </ListItemButton>
      </Link>
    </List>
  );
}

import { Box, Button, Paper, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { TreeView } from '@mui/lab';
import MenuService from '../../services/menuService';
import MenuFormDialog from './Dialog';
import DeleteDialog from '../../components/DeleteDialog';
import StyledTreeItem from '../../components/CustomTreeItem';

const listItems = Array(10).fill(0).map((_, i) => i);

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
function Menu() {
  const [items, setItems] = useState();
  const [isBrowser, setIsBrowser] = useState(false);
  const [dialog, setDialog] = useState();
  const [deleteEvents, setDeleteEvents] = useState({ open: false, itemId: 0, type: '' });

  const getMenuList = async () => {
    const list = await MenuService.Alllist();
    setItems(list.data);
  };

  async function deleteMenu(id) {
    await MenuService.Delete(id).then(() => {
      setDeleteEvents({ open: false, itemId: 0, type: '' });
    });
    getMenuList();
  }

  async function deleteSubMenu(id) {
    await MenuService.Deletesubmenu(id).then(() => {
      setDeleteEvents({ open: false, itemId: 0, type: '' });
    });
    getMenuList();
  }

  React.useEffect(() => {
    getMenuList();
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsBrowser(true);
    }
  }, []);
  const GetNewRank = async (data) => {
    const lastRanks = data?.map((item, index) => (
      ({ id: item.id, newRank: index + 1 })
    ));
    await MenuService.ChangeRank(lastRanks);
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const updatedItems = reorder(items, result.source.index, result.destination.index);
    GetNewRank(updatedItems);
    setItems(updatedItems);
  };

  return (
    <>
      <Paper sx={{ backgroundColor: 'grey.50', padding: 1, borderRadius: '10px' }}>
        <Box sx={{ marginBottom: 5, display: 'flex', justifyContent: 'flex-end' }}>
          <Button color="warning" variant="text" sx={{ alignSelf: 'stretch' }} onClick={() => setDialog({ data: {}, type: 'createmenu' })}>
            <Typography variant="overline">
              Menü Ekle
            </Typography>
            <AddIcon fontSize="14" />
          </Button>
        </Box>
        <TreeView
          sx={{ overflowX: 'hidden' }}
          defaultCollapseIcon={<RemoveIcon sx={{ color: 'white', alignSelf: 'baseline', backgroundColor: 'primary.main', borderRadius: '3px' }} />}
          defaultExpandIcon={<AddIcon sx={{ color: 'white', alignSelf: 'baseline', backgroundColor: 'primary.main', borderRadius: '3px' }} />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            {isBrowser ? (
              <Droppable droppableId="droppable">
                {(nodeProvided, nodeSnapshot) => (
                  <div
                    ref={nodeProvided.innerRef}
                    className={`list ${nodeSnapshot.isDraggingOver ? 'draggingOver' : ''}`}
                    {...nodeProvided.droppableProps}
                  >
                    {items?.map((item, index) => (
                      <Draggable key={item.id} draggableId={`${item.rank}-id`} index={index}>
                        {(provided, snapshot) => (
                          <div
                            className={`item ${snapshot.isDragging ? 'dragging' : ''}`}
                            style={provided.draggableProps.style}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Box key={item.id}>
                              <StyledTreeItem
                                color="#1a73e8"
                                bgColor="#e8f0fe"
                                nodeId={item.id}
                                labelText={item.name}
                                labelInfo={(
                                  <Box>
                                    {item.isSub && (
                                      <Button
                                        onClick={() => setDialog({ data: item, type: 'createsubmenu' })}
                                        variant="text"
                                        startIcon={<AddIcon style={{ fontSize: 12 }} />}
                                        style={{ textTransform: 'none' }}
                                        sx={{ mb: 0, mt: 0, marginRight: 2, fontSize: 12 }}
                                      >
                                        Alt Menü Ekle
                                      </Button>
                                    )}
                                    <Button
                                      onClick={() => setDialog({ data: item, type: 'editmenu' })}
                                      variant="text"
                                      color="warning"
                                      startIcon={<EditIcon style={{ fontSize: 12 }} />}
                                      style={{ textTransform: 'none' }}
                                      sx={{ mb: 0, mt: 0, marginRight: 2, fontSize: 12 }}
                                    >
                                      Düzenle
                                    </Button>
                                    <Button
                                      variant="text"
                                      color="error"
                                      onClick={() => setDeleteEvents({ open: true, itemId: item.id, type: 'menu' })}
                                      startIcon={<DeleteIcon style={{ fontSize: 12 }} />}
                                      style={{ textTransform: 'none' }}
                                      sx={{ mb: 0.5, mt: 0.5, mr: 2, fontSize: 12 }}
                                    >
                                      Sil
                                    </Button>
                                  </Box>
)}
                              >
                                {item.subMenus?.map((y) => {
                                  const matchingSubMenu = item.pages.find((z) => z.subMenuItemId === y.id);
                                  if (matchingSubMenu) {
                                    const newItem = { ...matchingSubMenu, ...y };
                                    return (
                                      <StyledTreeItem
                                        color="#e3742f"
                                        bgColor="#fcefe3"
                                        key={y.createdDate}
                                        nodeId={y.createdDate}
                                        labelText={y.name}
                                        labelInfo={(
                                          <Box>
                                            <Button
                                              onClick={() => setDialog({ data: newItem, type: 'editsubmenu' })}
                                              variant="text"
                                              color="warning"
                                              startIcon={<EditIcon style={{ fontSize: 12 }} />}
                                              style={{ textTransform: 'none' }}
                                              sx={{ mb: 0, mt: 0, marginRight: 1, fontSize: 12 }}
                                            >
                                              Düzenle
                                            </Button>
                                            <Button
                                              onClick={() => setDeleteEvents({ open: true, itemId: y.id, type: 'sub' })}
                                              variant="text"
                                              color="error"
                                              startIcon={<DeleteIcon style={{ fontSize: 12 }} />}
                                              style={{ textTransform: 'none' }}
                                              sx={{ mb: 0.5, mt: 0.5, fontSize: 12 }}
                                            >
                                              Sil
                                            </Button>
                                          </Box>
)}
                                      />
                                    );
                                  }
                                  return null;
                                })}
                              </StyledTreeItem>
                            </Box>
                          </div>
                        )}
                      </Draggable>

                    ))}
                    {nodeProvided.placeholder}
                  </div>
                )}
              </Droppable>
            )
              :
              null }

          </DragDropContext>
        </TreeView>
      </Paper>
      <MenuFormDialog getMenuList={getMenuList} open={dialog} setOpen={setDialog} />
      <DeleteDialog
        open={deleteEvents.open}
        onClose={() => setDeleteEvents({ ...deleteEvents, open: false })}
        onConfirm={() => (deleteEvents.type === 'sub' ? deleteSubMenu(deleteEvents.itemId) : deleteMenu(deleteEvents.itemId))}
      />
    </>
  );
}

export default Menu;

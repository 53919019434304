import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import React, { useContext, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SliderService from '../../services/sliderService';
import NewsService from '../../services/newsService';
import SectionService from '../../services/sectionService';
import { NotifyContext } from '../../contexts/NotifyContext';
import PageService from '../../services/pageService';
import { ApprovedStatus } from '../../constants/enum';
import { gridLocalize } from '../../utilities/helpers';
import { DataContext } from '../../contexts/DataContext';

const items = ['Page', 'News', 'Section', 'Slider'];
const getStatusLabel = (status) => {
  switch (status) {
    case ApprovedStatus.Pending:
      return ['Bekliyor', 'warning.main'];
    case ApprovedStatus.Confirm:
      return ['Onaylandı', 'success.main'];
    case ApprovedStatus.Rejected:
      return ['Reddedildi', 'error.main'];
    default:
      return '';
  }
};
const PageKey = [
  { field: 'id', headerName: 'Id', width: 100 },
  { field: 'metaKeyword', headerName: 'Meta Keyword', width: 200 },
  { field: 'metaDescription', headerName: 'Meta Description', width: 200 },
  { field: 'subject', headerName: 'Konu', width: 200 },
  { field: 'createdByUser', headerName: 'Oluşturan Kullanıcı', width: 150 },
  { field: 'createdDate',
    headerName: 'Oluşturulma Tarihi',
    width: 150,
    renderCell: (params) => {
      const splitValue = params.value.split('T');
      const split2 = splitValue[1].split(':');
      return (<Typography variant="body2">{`${splitValue[0]} ${split2[0]}:${split2[1]}`}</Typography>);
    } },
  { field: 'approvedByUser', headerName: 'Durumu Değiştiren', width: 150 },
  { field: 'isApprove', headerName: 'Durum', width: 100, renderCell: (params) => <Typography variant="body2" sx={{ color: getStatusLabel(params.value)[1] }}>{getStatusLabel(params.value)[0]}</Typography> },
];

const SectionKey = [{ field: 'id', headerName: 'Id', width: 100 },
  { field: 'name', headerName: 'İsim', width: 250 },
  { field: 'createdDate',
    headerName: 'Oluşturulma Tarihi',
    width: 150,
    renderCell: (params) => {
      const splitValue = params.value.split('T');
      const split2 = splitValue[1].split(':');
      return (<Typography variant="body2">{`${splitValue[0]} ${split2[0]}:${split2[1]}`}</Typography>);
    } },
  { field: 'createdByUser', headerName: 'Oluşturan Kullanıcı', width: 150 },
  { field: 'approvedByUser', headerName: 'Durumu Değiştiren', width: 150 },
  { field: 'isApprove', headerName: 'Durum', width: 100, renderCell: (params) => <Typography variant="body2" sx={{ color: getStatusLabel(params.value)[1] }}>{getStatusLabel(params.value)[0]}</Typography> },
];
const SectionItemKey = [{ field: 'id', headerName: 'Id', width: 100 },
  { field: 'name', headerName: 'İsim', width: 250 },
  { field: 'createdDate',
    headerName: 'Oluşturulma Tarihi',
    width: 150,
    renderCell: (params) => {
      const splitValue = params.value.split('T');
      const split2 = splitValue[1].split(':');
      return (<Typography variant="body2">{`${splitValue[0]} ${split2[0]}:${split2[1]}`}</Typography>);
    } },
  { field: 'createdByUser', headerName: 'Oluşturan Kullanıcı', width: 150 },
  { field: 'approvedByUser', headerName: 'Durumu Değiştiren', width: 150 },
  { field: 'isApprove', headerName: 'Durum', width: 100, renderCell: (params) => <Typography variant="body2" sx={{ color: getStatusLabel(params.value)[1] }}>{getStatusLabel(params.value)[0]}</Typography> },
];
const SliderKey = [{ field: 'id', headerName: 'Id', width: 100 },
  { field: 'title', headerName: 'Başlık', width: 150 },
  { field: 'webImageUrl', headerName: 'Web Fotoğraf Uzantısı', width: 250 },
  { field: 'mobileImageUrl', headerName: 'Mobil Fotoğraf Uzantısı', width: 250 },
  { field: 'createdDate',
    headerName: 'Oluşturulma Tarihi',
    width: 150,
    renderCell: (params) => {
      const splitValue = params.value.split('T');
      const split2 = splitValue[1].split(':');
      return (<Typography variant="body2">{`${splitValue[0]} ${split2[0]}:${split2[1]}`}</Typography>);
    } },
  { field: 'createdByUser', headerName: 'Oluşturan Kullanıcı', width: 150 },
  { field: 'approvedByUser', headerName: 'Durumu Değiştiren', width: 150 },
  { field: 'isApprove', headerName: 'Durum', width: 100, renderCell: (params) => <Typography variant="body2" sx={{ color: getStatusLabel(params.value)[1] }}>{getStatusLabel(params.value)[0]}</Typography> },

];

const NewsKey = [{ field: 'id', headerName: 'Id', width: 100 },
  { field: 'subject', headerName: 'Konu', width: 200 },
  { field: 'category', headerName: 'Kategori', width: 100 },,
  { field: 'createdDate',
    headerName: 'Oluşturulma Tarihi',
    width: 150,
    renderCell: (params) => {
      const splitValue = params.value.split('T');
      const split2 = splitValue[1].split(':');
      return (<Typography variant="body2">{`${splitValue[0]} ${split2[0]}:${split2[1]}`}</Typography>);
    } },
  { field: 'createdByUser', headerName: 'Oluşturan Kullanıcı', width: 200 },
  { field: 'approvedByUser', headerName: 'Durumu Değiştiren', width: 150 },
  { field: 'isApprove', headerName: 'Durum', width: 100, renderCell: (params) => <Typography variant="body2" sx={{ color: getStatusLabel(params.value)[1] }}>{getStatusLabel(params.value)[0]}</Typography> },

];

export default function ApprovePages() {
  const [checked, setChecked] = React.useState([]);
  const { approvePageList,
    setApprovePageList,
    approveNewsList,
    setApproveNewsList,
    approveSectionList,
    setApproveSectionList,
    approveSliderList,
    setApproveSliderList } = useContext(DataContext);
  const { Notify } = useContext(NotifyContext);
  const [sectionItemList, setSectionItemList] = useState();

  const [selectItem, setSelectItem] = useState('Page');
  const [selectSection, setSelectSection] = useState();

  const AprroveListPage = async () => {
    await PageService.GetApproveList().then((response) => {
      setApprovePageList(response?.data);
    });
  };
  const ApproveListSection = async () => {
    await SectionService.GetApproveList().then((response) => {
      setApproveSectionList(response?.data);
    });
  };
  const ApproveListSlider = async () => {
    await SliderService.GetApproveList().then((response) => {
      setApproveSliderList(response?.data);
    });
  };
  const ApproveListNews = async () => {
    await NewsService.GetApproveList().then((response) => {
      setApproveNewsList(response?.data);
    });
  };

  const ApproveListSectionItem = async () => {
    if (selectSection) {
      await SectionService.GetApproveListItemByMenuId(selectSection).then((response) => {
        setSectionItemList(response?.data);
      });
    }
  };
  const ApproveUpdate = async (status) => {
    if (selectItem === 'Page') {
      await PageService.UpdateApprove(status, checked);
      AprroveListPage();
    }
    if (selectItem === 'News') {
      await NewsService.ApproveUpdate(status, checked);
      ApproveListNews();
    } else if (selectItem === 'Section') {
      await SectionService.ApproveUpdate(status, checked);
      ApproveListSection();
    } else if (selectItem === 'Slider') {
      await SliderService.ApproveUpdate(status, checked);
      ApproveListSlider();
    } else if (selectItem === 'Section Item') {
      await SectionService.ApproveUpdateItem(status, checked);
      ApproveListSectionItem();
    }
  };
  React.useEffect(() => {
    AprroveListPage();
    ApproveListNews();
    ApproveListSection();
    ApproveListSlider();
  }, []);
  React.useEffect(() => {
    ApproveListSectionItem();
  }, [selectSection]);

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Onay Bekleyenler</InputLabel>
        <Select
          defaultValue=""
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectItem !== 'Section Item' ? selectItem : 'Section'}
          label="Onay Bekleyenler"
          onChange={(event) => { setSelectItem(event.target.value); setSelectSection(); }}
        >
          {items?.map((item, index) => (
            <MenuItem key={index} value={item}>{item}</MenuItem>

          ))}
        </Select>
      </FormControl>
      {(selectItem === 'Section' || selectItem === 'Section Item') && (
      <FormControl fullWidth sx={{ mt: 3 }}>
        <InputLabel id="demo-simple-select-label">İçerik Elemanları</InputLabel>
        <Select
          defaultValue=""
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectSection}
          label="İçerik Elemanları"
          onChange={(event) => { setSelectSection(event.target.value); setSelectItem('Section Item'); }}
        >
          {approveSectionList?.map((item) => (
            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      )}
      <div style={{ height: 650, width: '100%', marginTop: 20 }}>
        <Typography variant="body" color="primary">Onay Bekleyen İşlemler</Typography>
        <DataGrid
          onSelectionModelChange={(item) => {
            setChecked(item.map((id) => ({ id })));
          }}
          localeText={{ ...gridLocalize,
            MuiTablePagination: {
              labelRowsPerPage: 'Sayfa Başına Kayıt',
            } }}
          rows={(selectItem === 'Page' && approvePageList?.filter((f) => f.isApprove === ApprovedStatus.Pending)) || (selectItem === 'Section' && approveSectionList?.filter((f) => f.isApprove === ApprovedStatus.Pending)) ||
          (selectItem === 'Slider' && approveSliderList?.filter((f) => f.isApprove === ApprovedStatus.Pending)) || (selectItem === 'News' && approveNewsList?.filter((f) => f.isApprove === ApprovedStatus.Pending)) || (selectItem === 'Section Item' && (sectionItemList?.filter((f) => f.isApprove === ApprovedStatus.Pending) || []))}
          columns={(selectItem === 'Page' && PageKey) || (selectItem === 'Section' && SectionKey) ||
          (selectItem === 'Slider' && SliderKey) || (selectItem === 'News' && NewsKey) || (selectItem === 'Section Item' && SectionItemKey)}
          pageSize={10}
          pageSizeOptions={[5]}
          checkboxSelection
        />
      </div>
      <Box sx={{ flexDirection: 'row', justifyContent: 'space-around', display: 'flex' }}>
        <Button
          disableElevation
          style={{ fontSize: 15, marginTop: 40, alignItems: 'center' }}
          variant="contained"
          onClick={() => ApproveUpdate(ApprovedStatus.Confirm)}
        >
          İşlemleri Onayla
        </Button>
        <Button
          disableElevation
          style={{ fontSize: 15, marginTop: 40, alignItems: 'center' }}
          variant="contained"
          color="error"
          onClick={() => ApproveUpdate(ApprovedStatus.Rejected)}
        >
          İşlemleri Reddet
        </Button>
      </Box>
    </Box>
  );
}

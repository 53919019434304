import { AppBar, Button, Dialog, DialogActions, DialogContent, FormControlLabel, IconButton, Switch, TextField, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useContext, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { NotifyContext } from '../../contexts/NotifyContext';
import { DataContext } from '../../contexts/DataContext';
import MenuService from '../../services/menuService';
import AntSwitch from '../../components/Switch';

const headerLabels = {
  createsubmenu: 'Alt Menü Ekle',
  createmenu: 'Menü Ekle',
  editmenu: 'Menü Düzenle',
  editusbmenu: 'Alt Menü Düzenle',
  deletemenu: 'Menü Sil',
  deletesubmenu: 'Alt Menü Sil',
};
export default function MenuFormDialog({ open = false, setOpen, getMenuList }) {
  const data = open?.data;
  const { Notify } = useContext(NotifyContext);
  const { setSelectedIndex } = React.useContext(DataContext);
  const navigate = useNavigate();
  function ModalSwitch() {
    const [formData, setFormData] = useState(open?.data || {});

    const createMenu = async () => {
      if (!formData?.isSub) {
        if (formData.page?.subject !== undefined && formData.page?.metaKeyword !== undefined && formData.page?.metaDescription !== undefined && formData?.name) {
          await MenuService.Create(formData).then((response) => {
            if (!response?.error) {
              Notify('Menü ve sayfa oluşturuldu. Sayfalar kısmından erişebilirsiniz.', 'success');
              if (!response.data.isSub) {
                navigate(`/page/detail/detail/${response.data.pages[0].id}`);
              }
            }
            setSelectedIndex(13);
          });
          await getMenuList();
        } else {
          Notify('Tüm alanlar zorunludur!', 'error');
        }
      } else if (formData?.name) {
        await MenuService.Create(formData);
        await getMenuList();
      } else {
        Notify('Tüm alanlar zorunludur!', 'error');
      }
    };

    const createSubMenu = async () => {
      if (formData.page?.subject !== undefined && formData.page?.metaKeyword !== undefined && formData.page?.metaDescription !== undefined && formData?.name) {
        await MenuService.Createsubmenu({ ...formData, menuId: data.id, name: formData.name }).then((response) => {
          if (!response?.error) {
            Notify('Alt menü ve sayfa oluşturuldu. Sayfalar kısmından erişebilirsiniz.', 'success');
            navigate(`/page/detail/detail/${response?.data.pages[response.data.pages.length - 1].id}`);
          }
        });
        await getMenuList();
      } else {
        Notify('Tüm alanlar zorunludur!', 'error');
      }
    };

    const updateMenu = async () => {
      if (formData.isSub) {
        await MenuService.Update({ id: data.id, status: data.status, name: formData.name, isSub: formData.isSub });
        await getMenuList();
        setOpen();
      } else if (!formData.isSub && formData?.subMenus.length === 0) {
        if (formData.page?.subject !== undefined && formData.page?.metaKeyword !== undefined && formData.page?.metaDescription !== undefined && formData?.name) {
          await MenuService.Update({ id: data.id,
            status: data.status,
            name: formData.name,
            isSub: formData.isSub,
            page: {
              metaKeyword: formData.page?.metaKeyword,
              metaDescription: formData.page?.metaDescription,
              subject: formData.page?.subject,
              body: formData.page?.body,
            } });

          await getMenuList();
          setOpen();
        } else {
          Notify('Tüm alanlar zorunludur!', 'error');
        }
      }
    };

    const updateSubMenu = async () => {
      await MenuService.Updatesubmenu({ id: data.id,
        status: formData.status,
        name: formData.name,
        page: {
          metaKeyword: formData.metaKeyword,
          metaDescription: formData.metaDescription,
          subject: formData.subject,
          body: '',
          menuItemId: formData.menuItemId,
        } });
      await getMenuList();
    };

    useEffect(() => {
      setFormData(open?.data || {});
    }, [open]);
    {
      switch (String(open?.type)) {
        case 'createsubmenu':
          return (
            <>
              <DialogContent>
                <TextField
                  autoFocus
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  margin="dense"
                  id="name"
                  label="Alt Menü Adı"
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <TextField
                  onChange={(e) => setFormData({ ...formData, page: { ...formData?.page, metaKeyword: e.target.value, body: '' } })}
                  margin="dense"
                  label="Meta Keyword"
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <TextField
                  onChange={(e) => setFormData({ ...formData, page: { ...formData?.page, metaDescription: e.target.value } })}
                  margin="dense"
                  label="Meta Description"
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <TextField
                  onChange={(e) => setFormData({ ...formData, page: { ...formData?.page, subject: e.target.value } })}
                  margin="dense"
                  label="Subject"
                  type="text"
                  fullWidth
                  variant="standard"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen()}>İptal</Button>
                <Button onClick={() => createSubMenu().then(() => setOpen())}>Ekle</Button>
              </DialogActions>
            </>
          );

        case 'createmenu':
          return (
            <>
              <DialogContent>
                <TextField
                  autoFocus
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  margin="dense"
                  id="name"
                  label="Menü Adı"
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <FormControlLabel label="Alt menü içerecek mi?" control={<AntSwitch checked={formData?.isSub} onChange={(e) => setFormData((prev) => ({ ...prev, isSub: e.target.checked }))} />} />
                {!formData?.isSub && (
                <Box>
                  <TextField
                    onChange={(e) => setFormData({ ...formData, page: { ...formData?.page, metaKeyword: e.target.value, body: '' } })}
                    margin="dense"
                    label="Meta Keyword"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    onChange={(e) => setFormData({ ...formData, page: { ...formData?.page, metaDescription: e.target.value } })}
                    margin="dense"
                    label="Meta Description"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    onChange={(e) => setFormData({ ...formData, page: { ...formData?.page, subject: e.target.value } })}
                    margin="dense"
                    label="Subject"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen()}>İptal</Button>
                <Button onClick={() => createMenu().then(() => setOpen())}>Ekle</Button>
              </DialogActions>
            </>
          );

        case 'editmenu':
          return (
            <>
              <DialogContent>
                <TextField
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  autoFocus
                  placeholder={formData?.name}
                  margin="dense"
                  id="name"
                  label="Menü Adı"
                  type="text"
                  fullWidth
                  variant="standard"
                />
                {!formData.isSub && (
                <>
                  <TextField
                    onChange={(e) => setFormData({ ...formData, page: { ...formData?.page, metaKeyword: e.target.value, body: '' } })}
                    margin="dense"
                    placeholder={formData?.pages?.filter((f) => f.menuItemId === formData.id)[0]?.metaKeyword || ''}
                    label="Meta Keyword"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    onChange={(e) => setFormData({ ...formData, page: { ...formData?.page, metaDescription: e.target.value } })}
                    margin="dense"
                    placeholder={formData?.pages?.filter((f) => f.menuItemId === formData.id)[0]?.metaDescription || ''}
                    label="Meta Description"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    onChange={(e) => setFormData({ ...formData, page: { ...formData?.page, subject: e.target.value } })}
                    placeholder={formData?.pages?.filter((f) => f.menuItemId === formData.id)[0]?.subject || ''}
                    margin="dense"
                    label="Subject"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                </>
                )}
                <FormControlLabel
                  label="Alt Menü"
                  control={(
                    <Switch
                      checked={formData?.isSub}
                      onChange={(e) => (formData.subMenus.length === 0
                        ? setFormData((prev) => ({ ...prev, isSub: e.target.checked })) :
                        Notify('Alt menüleri olan bir menünün, alt menü seçeneğini kapatamazsın!', 'error'))
              }
                    />
)}
                />
                <FormControlLabel label="Durum" control={<Switch checked={formData?.status} onChange={(e) => setFormData((prev) => ({ ...prev, status: e.target.checked }))} />} />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen()}>İptal</Button>
                <Button onClick={() => updateMenu()}>Kaydet</Button>
              </DialogActions>
            </>
          );
        case 'editsubmenu':
          return (
            <>
              <DialogContent>
                <TextField
                  value={formData?.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Alt Menü Adı"
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <TextField
                  onChange={(e) => setFormData({ ...formData, metaKeyword: e.target.value })}
                  margin="dense"
                  label="Meta Keyword"
                  value={formData?.metaKeyword}
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <TextField
                  onChange={(e) => setFormData({ ...formData, metaDescription: e.target.value })}
                  margin="dense"
                  label="Meta Description"
                  value={formData?.metaDescription}
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <TextField
                  onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                  margin="dense"
                  label="Subject"
                  value={formData?.subject}
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <FormControlLabel label="Durum" control={<Switch checked={formData.status} onChange={(e) => setFormData({ ...formData, status: e.target.checked })} />} />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen()}>İptal</Button>
                <Button onClick={() => updateSubMenu().then(() => setOpen())}>Kaydet</Button>
              </DialogActions>
            </>
          );
      }
    }
  }

  return (
    <div>
      {open ? (
        <Dialog disableEnforceFocus open={!!open} onClose={() => setOpen(!open)}>
          <AppBar sx={{ position: 'relative', backgroundColor: open.type.includes('deletemenu') || open.type.includes('deletesubmenu') ? 'error.main' : 'primary' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {headerLabels[open.type]}
              </Typography>
            </Toolbar>
          </AppBar>
          <ModalSwitch />
        </Dialog>
      ) : null}
    </div>
    // <div>
    //   <Dialog open={open} onClose={() => setOpen(!open)}>
    //     <ModalSwitch />
    //   </Dialog>
    // </div>
  );
}

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate, useParams } from 'react-router-dom';
import UserService from '../../services/userService';
import { NotifyContext } from '../../contexts/NotifyContext';
import { ButtonLoaderContext } from '../../contexts/ButtonLoaderContext';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        TRA
      </Link>
      {' '}
      {new Date().getFullYear()}
      .
    </Typography>
  );
}

const theme = createTheme();

export default function ResetPassword() {
  const { loading, setLoading } = React.useContext(ButtonLoaderContext);
  const [showPassword, setShowPassword] = React.useState(false);
  const [password, setPassword] = React.useState();
  const [confirmPassword, setConfirmPassword] = React.useState();
  const { Notify } = React.useContext(NotifyContext);
  const navigate = useNavigate();

  const { email, token } = useParams();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const Reset = async () => {
    if (confirmPassword === password && confirmPassword && password) {
      await UserService.ResetPassword({ email, token, password }).then((response) => {
        if (!response?.error) {
          navigate('/login');
        }
      });
    } else {
      Notify('Şifreler eşleşmiyor!', 'error');
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(/logo.svg)',
            backgroundRepeat: 'repeat',
            backgroundColor: (t) =>
              (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundPosition: 'center',
          }}
        />

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              height: '70%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} />
            <Typography component="h1" variant="h5">
              Şifremi Yenile
            </Typography>
            <Box sx={{ mt: 1, paddingRight: 7, paddingLeft: 7 }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">Yeni Şifre</InputLabel>
                <OutlinedInput
                  type={showPassword ? 'text' : 'password'}
                  onChange={(event) => setPassword(event.target.value)}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  label="Password"
                  id="password"
                  name="password"
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 3 }}>
                <InputLabel htmlFor="outlined-adornment-password">Şifreyi Onayla</InputLabel>
                <OutlinedInput
                  type={showPassword ? 'text' : 'password'}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  label="Password"
                  id="password"
                  name="password"
                />
              </FormControl>
              <Button
                loading={loading}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={Reset}
              >
                Onayla
              </Button>
            </Box>
          </Box>

        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

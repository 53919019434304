import React from 'react';
import { cookies } from '../App';
import NewsService from '../services/newsService';
import PageService from '../services/pageService';
import SectionService from '../services/sectionService';
import SliderService from '../services/sliderService';

const DataContext = React.createContext();

function DataProvider({ children }) {
  const [sectionList, setSectionList] = React.useState();
  const [newsList, setNewsList] = React.useState();

  const [approvePageList, setApprovePageList] = React.useState();
  const [approveSectionList, setApproveSectionList] = React.useState();
  const [approveSliderList, setApproveSliderList] = React.useState();
  const [approveNewsList, setApproveNewsList] = React.useState();
  const [selectedIndex, setSelectedIndex] = React.useState(Number(localStorage.getItem('selectedIndex')) || 1);
  const [sectionItemList, setSectionItemList] = React.useState();

  const user = cookies.get('user');
  const AllSection = async () => {
    const result = await SectionService.AllList();
    if (!result?.error) setSectionList(result?.data);
  };
  const AllNews = async () => {
    const result = await NewsService.AllList();
    if (!result?.error) setNewsList(result?.data);
  };

  React.useEffect(() => {
    if (user) {
      AllSection();
      AllNews();
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem('selectedIndex', selectedIndex);
  }, [selectedIndex]);

  const contextValue = React.useMemo(() => ({
    sectionItemList,
    setSectionItemList,
    sectionList,
    newsList,
    setSectionList,
    setNewsList,
    approvePageList,
    setApprovePageList,
    approveNewsList,
    setApproveNewsList,
    approveSectionList,
    setApproveSectionList,
    approveSliderList,
    setApproveSliderList,
    selectedIndex,
    setSelectedIndex,
  }), [sectionList, newsList, approvePageList, sectionItemList, approveNewsList, approveSectionList, approveSliderList, selectedIndex]);
  return (
    <DataContext.Provider value={contextValue}>
      {children}
    </DataContext.Provider>
  );
}
export { DataContext, DataProvider };

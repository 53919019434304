import { AppBar, Autocomplete, Button, CardMedia, Dialog, DialogContent, FormControl, FormControlLabel, IconButton, TextField, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import React, { useState, useEffect } from 'react';
import AntSwitch from '../../../../components/Switch';
import FileService from '../../../../services/fileService';
import SliderService from '../../../../services/sliderService';

function UpdateDialog({ open, setOpen, onChange }) {
  const [updateRequest, setUpdateRequest] = useState(open);
  const [files, setFiles] = useState([]);
  const getFiles = async () => {
    await FileService.ImageList().then((response) => {
      setFiles(response?.data);
    });
  };

  const UpdateSlider = async () => {
    await SliderService.Update(updateRequest).then((response) => {
      if (!response?.error) {
        onChange();
        setOpen(false);
      }
    });
  };
  const UploadFile = async (data) => {
    const file = new FormData();
    file.append('Files', data);
    await FileService.Upload(file).then((response) => {
      if (!response?.error) getFiles();
    });
  };

  const renderOption = (props, option) => (
    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
      <CardMedia
        component="img"
        height="50"
        image={option.path}
        style={{ width: '50px' }}
      />
      {option.name}
      .
      {option.extension}
    </Box>
  );
  useEffect(() => {
    getFiles();
  }, [open]);

  return (
    <Dialog disableEnforceFocus fullWidth={false} open={!!open} onClose={() => setOpen(!open)}>
      <AppBar sx={{ position: 'relative', backgroundColor: 'primary' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen()}
            aria-label="close"
          >
            <EditIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Slider Düzenle
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <div>
          <Typography color="black" sx={{ textAlign: 'center' }}>Mevcut fotoğraf</Typography>
          <img
            src={open?.webImageUrl}
            loading="lazy"
            width={500}
            height={150}
            alt="web"
          />
        </div>
        <TextField
          defaultValue={open?.title}
          autoFocus
          margin="dense"
          id="name"
          label="Başlık"
          def
          fullWidth
          variant="outlined"
          onChange={(event) => setUpdateRequest({ ...updateRequest, title: event.target.value })}
        />
        <FormControl margin="dense" fullWidth>
          <Autocomplete
            value={files?.find((item) => item.path === updateRequest.webImageUrl) ?? null}
            options={files}
            fullWidth
            getOptionLabel={(option) => (option ? `${option.name}.${option.extension}` : '')}
            renderOption={(props, option) => renderOption(props, option)}
            onChange={(event, value) => setUpdateRequest({ ...updateRequest, webImageUrl: value?.path })}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Web Fotoğraf"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </FormControl>
        <FormControl margin="dense" fullWidth>
          <Autocomplete
            value={files.find((item) => item.path === updateRequest.mobileImageUrl) ?? null}
            options={files}
            fullWidth
            getOptionLabel={(option) => (option ? `${option.name}.${option.extension}` : '')}
            renderOption={(props, option) => renderOption(props, option)}
            onChange={(event, value) => setUpdateRequest({ ...updateRequest, mobileImageUrl: value?.path })}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Mobil Fotoğraf"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </FormControl>
        <TextField fullWidth sx={{ mt: 1 }} id="outlined-basic" variant="outlined" type="file" draggable onChange={(e) => UploadFile(e.target.files[0])} />
        <FormControlLabel
          control={<AntSwitch checked={updateRequest?.status || false} onChange={(e) => setUpdateRequest({ ...updateRequest, status: e.target.checked })} />}
          slotProps={{ typography: { fontSize: 12 } }}
          label="Durum"
        />
        <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
          <Button onClick={UpdateSlider} variant="contained">Güncelle</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
export default UpdateDialog;

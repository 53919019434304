import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Toolbar } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ReCAPTCHA from 'react-google-recaptcha';
import { AuthContext } from '../../contexts/AuthContext';
import { Button } from '../../components/Button';
import { ButtonLoaderContext } from '../../contexts/ButtonLoaderContext';
import UserService from '../../services/userService';
import { NotifyContext } from '../../contexts/NotifyContext';

const SITE_KEY = '6LcBkKEcAAAAAC1tIN7Bgl72TgrlAITCTCnBI98q';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        TRA
      </Link>
      {' '}
      {new Date().getFullYear()}
      .
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {
  const { login } = React.useContext(AuthContext);
  const { loading, setLoading } = React.useContext(ButtonLoaderContext);
  const [open, setOpen] = React.useState(false);
  const [forgotEmail, setForgotEmail] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const { Notify } = React.useContext(NotifyContext);
  const captchaRef = React.useRef();

  const handleSubmitLogin = async (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get('email') && data.get('password')) {
      await login(data.get('email'), data.get('password'), captchaRef);
    } else Notify('Tüm alanlar zorunludur!', 'error');
    setLoading(false);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: '100vh' }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: 'url(/logo.svg)',
              backgroundRepeat: 'repeat',
              backgroundColor: (t) =>
                (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
              backgroundPosition: 'center',
            }}
          />

          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                height: '70%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} />
              <Typography component="h1" variant="h5">
                Giriş Yap
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmitLogin} sx={{ mt: 1, paddingRight: 7, paddingLeft: 7 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="E-mail Adresi"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />

                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Şifre</InputLabel>
                  <OutlinedInput
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )}
                    label="Password"
                    id="password"
                    name="password"
                  />
                </FormControl>
                <Box sx={{ mt: 3 }}>
                  <ReCAPTCHA
                    className="recaptcha"
                    sitekey={SITE_KEY}
                    ref={captchaRef}
                  />
                </Box>
                <Button
                  loading={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Giriş Yap
                </Button>
              </Box>
              <Box sx={{ width: '100%', paddingLeft: 7 }}>
                <Button variant="text" onClick={() => setOpen(true)} color="primary" sx={{ mt: 0, textTransform: 'none', textAlign: 'end' }}>
                  Şifreni mi unuttun ?
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
      <Dialog open={open} onClose={null}>
        <DialogTitle>Şifremi unuttum</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Şifresini unuttuğunuz e-mail adresinizi giriniz. Kayıtlı bir hesap varsa size bir mail göndereceğiz.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="E-mail Adresi"
            type="email"
            fullWidth
            variant="standard"
            onChange={(event) => setForgotEmail(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={async () => UserService.ForgotPassword({ email: forgotEmail }).then((response) => {
            if (!response?.error) setOpen(false);
          })}
          >
            Gönder
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { axiosCMSService } from './axios.config';

const endpoints = {
  list: 'userrole/list',
  update: 'userrole/update',
  getbyid: 'userrole/getbyid',
  getroles: 'userrole/getroles',

};

const RoleService = {
  async List() {
    return axiosCMSService.get(endpoints.list);
  },
  async GetRoles() {
    return axiosCMSService.get(endpoints.getroles);
  },
  async Update(data) {
    return axiosCMSService.post(endpoints.update, data);
  },
  async GetById(id) {
    return axiosCMSService.get(`${endpoints.getbyid}?id=${id}`);
  },
};

export default RoleService;

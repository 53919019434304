import { Box, Button, Card, Divider, FormControlLabel, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import PageService from '../../../../services/pageService';
import AntSwitch from '../../../../components/Switch';
import SliderService from '../../../../services/sliderService';
import DeleteDialog from '../../../../components/DeleteDialog';
import { ApprovedStatus } from '../../../../constants/enum';
import LinkedDialog from '../../../../components/LinkedDialog';
import UpdateDialog from './updateDialog';
import AddDialog from './addDialog';

function SliderSection({ params }) {
  const [slider, setSlider] = useState();
  const [deleteEvents, setDeleteEvents] = useState({ open: false, itemId: 0 });
  const [sliderList, setSliderList] = useState();
  const [linkedOpen, setLinkedOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);

  const getSliders = async () => {
    const result = await SliderService.AllList(false);
    setSliderList(result?.data);
  };

  const GetLinkedSlider = async (id) => {
    const result = await PageService.GetLinkedSlider({ pageId: id, filterApproved: false });
    setSlider(result?.data);
  };

  const UnlinkSlider = async (pageId, sliderId) => {
    await PageService.UnlinkSlider({ pageId, unlinkedItemId: sliderId }).then(() => {
      setDeleteEvents({ open: false, itemId: 0 });
    });
    GetLinkedSlider(params.id);
  };

  const UpdateStatus = async (data) => {
    await SliderService.UpdateStatus(data);
    GetLinkedSlider(params.id);
  };
  useEffect(() => {
    GetLinkedSlider(params.id);
    getSliders();
  }, []);
  return (
    <>
      <Card elevation={10} sx={{ backgroundColor: 'grey.50', padding: 3, borderRadius: '10px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color="text.secondary" variant="h5">
            Slider
          </Typography>
          <Box>
            <Button
              variant="text"
              color="warning"
              startIcon={<AddIcon style={{ fontSize: 12 }} />}
              style={{ textTransform: 'none' }}
              onClick={() => setLinkedOpen({ pageId: params.id, status: true, linkedItem: slider })}
              sx={{ mb: 0.5, mt: 0.5, mr: 2, fontSize: 12 }}
            >
              Slider Bağla
            </Button>
            <Button
              variant="text"
              color="secondary"
              startIcon={<AddIcon style={{ fontSize: 12 }} />}
              style={{ textTransform: 'none' }}
              onClick={() => setAddOpen({ status: true, pageId: params.id, menuItemName: params.menuItemName, subMenuItemName: params.subMenuItemName || '' })}
              sx={{ mb: 0.5, mt: 0.5, mr: 2, fontSize: 12 }}
            >
              Slider Ekle
            </Button>
          </Box>
        </Box>
        <Grid2 marginTop={2} container spacing={2}>
          {slider?.map((x, i) => (
            <>
              <Grid2 item display="flex" lg={12} xs={12} flexWrap="wrap" justifyContent="space-between" alignItems="center" key={x.id}>
                <Typography variant="body2">
                  {x.title}
                </Typography>
                {x.isApprove === ApprovedStatus.Rejected && (
                <Typography color="error" variant="body2" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                  Reddedilmiş !
                </Typography>
                )}
                {x.isApprove === ApprovedStatus.Pending && (
                <Typography color="orange" variant="body2" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                  Onay Bekleniyor !
                </Typography>
                )}
                {x.isApprove === ApprovedStatus.Confirm && (
                <Typography color="green" variant="body2" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                  Onaylanmış.
                </Typography>
                )}
                <Box flexWrap="wrap" className="sectionInfo" sx={{ display: 'flex', columnGap: 1 }}>

                  <FormControlLabel
                    control={<AntSwitch checked={x.status} onChange={() => UpdateStatus({ sliderId: x.id, status: !x.status })} />}
                    slotProps={{ typography: { fontSize: 12 } }}
                    label="Durum"
                  />
                  <Button
                    variant="text"
                    color="secondary"
                    startIcon={<EditIcon style={{ fontSize: 12 }} />}
                    style={{ textTransform: 'none' }}
                    onClick={() => setUpdateOpen({ title: x.title, webImageUrl: x.webImageUrl, mobileImageUrl: x.mobileImageUrl, id: x.id, status: x.status })}
                    sx={{ mb: 0, mt: 0, marginRight: 2, fontSize: 12 }}
                  >
                    Düzenle
                  </Button>
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => setDeleteEvents({ open: true, itemId: x.id })}
                    startIcon={<DeleteIcon style={{ fontSize: 12 }} />}
                    style={{ textTransform: 'none' }}
                    sx={{ mb: 0.5, mt: 0.5, mr: 2, fontSize: 12 }}
                  >
                    Bağlantıyı Sil
                  </Button>
                </Box>

              </Grid2>
              {i + 1 !== slider.length &&
              <Divider light sx={{ height: 1, width: '100%' }} />
              }
            </>
          ))}
        </Grid2>
      </Card>
      <UpdateDialog open={updateOpen} setOpen={setUpdateOpen} onChange={() => GetLinkedSlider(params.id)} />
      <AddDialog open={addOpen} setOpen={setAddOpen} onChange={() => GetLinkedSlider(params.id)} />
      <LinkedDialog
        type="Slider"
        header="Slider Bağla"
        open={linkedOpen}
        setOpen={setLinkedOpen}
        onClose={() => setLinkedOpen({ ...linkedOpen, status: false })}
        list={sliderList}
        getLinked={() => GetLinkedSlider(params.id)}
      />
      <DeleteDialog
        open={deleteEvents.open}
        onClose={() => setDeleteEvents({ ...deleteEvents, open: false })}
        onConfirm={() => UnlinkSlider(params.id, deleteEvents.itemId)}
      />
    </>
  );
}

export default SliderSection;

import { SearchOutlined } from '@mui/icons-material';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, InputAdornment, List, ListItemButton, ListItemIcon, ListItemText, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PageService from '../services/pageService';
import { Button } from './Button';

function LinkedDialog({ open, onClose, list, getLinked, setOpen, header, type }) {
  const [filter, setFilter] = useState();
  const [checked, setChecked] = useState([]);

  const LinkingOperation = async (param) => {
    if (type === 'News') {
      const result = await PageService.LinkNews({ newsId: param, pageId: open.pageId });
      getLinked();
      if (!result?.error) {
        setChecked([]);
        setOpen();
      }
    } else if (type === 'Slider') {
      const result = await PageService.LinkSlider({ sliderId: param, pageId: open.pageId });
      getLinked();
      if (!result?.error) {
        setChecked([]);
        setOpen();
      }
    }
  };
  useEffect(() => (
    () => setChecked([])
  ), []);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  return (
    <DialogContent>
      <Dialog PaperProps={{ sx: { borderRadius: '10px', height: '80vh' } }} fullWidth open={open} onClose={onClose}>
        <DialogTitle color="warning.light" id="scroll-dialog-title">{header}</DialogTitle>
        <TextField
          value={filter}
          onChange={(x) => setFilter(x.currentTarget.value)}
          sx={{ mx: 4 }}
          label="Ara"
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
        <DialogContent>
          <List>
            {list?.filter((x) => !open?.linkedItem?.some((y) => y.id === x.id)).filter((x) => (filter ?
              (type === 'News' ? x.subject : (type === 'Slider' ? x.title : x.name)).toUpperCase()
                .includes(filter?.toUpperCase()) : true))?.map((x, index) => (
                  <>
                    <ListItemButton key={x.id} onClick={handleToggle(x.id)}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(x.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={type === 'News' ? x.subject : (type === 'Slider' ? x.title : x.name)}
                      />
                    </ListItemButton>
                    <Divider />
                  </>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => LinkingOperation(checked)}>Ekle</Button>
          <Button color="error" onClick={() => setOpen(false)}>İptal</Button>

        </DialogActions>
      </Dialog>
    </DialogContent>
  );
}
export default LinkedDialog;

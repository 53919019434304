import { axiosCMSService } from './axios.config';

const endpoints = {
  create: 'shortlink/create',
  getactualurl: 'shortlink/getactualurl',
  getallurls: 'shortlink/getallurls',
  delete: 'shortlink/delete',
};

const LinkShortenerService = {
  async Create(data) {
    return axiosCMSService.post(endpoints.create, data);
  },
  async GetActualUrl(data) {
    return axiosCMSService.post(endpoints.getactualurl, data);
  },
  async GetAllUrls() {
    return axiosCMSService.get(endpoints.getallurls);
  },
  async Delete(data) {
    return axiosCMSService.delete(`${endpoints.delete}?id=${data}`);
  },
};

export default LinkShortenerService;

import { Button as MUIButton, CircularProgress } from '@mui/material';

export function Button({ sx, variant = 'contained', type = 'submit', children, loading = false, ...props }) {
  return (
    <MUIButton
      disabled={loading}
      type={type}
      variant={variant}
      sx={[{ mt: 3, mb: 2 }, sx]}
      {...props}
    >
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: 'green',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
      {children}
    </MUIButton>
  );
}

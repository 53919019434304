import moment from 'moment';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Cookies from 'universal-cookie';
import { useContext } from 'react';
import { AuthContext, AuthProvider } from './contexts/AuthContext';
import { ButtonLoaderProvider } from './contexts/ButtonLoaderContext';
import { DataProvider } from './contexts/DataContext';
import { NotifyProvider } from './contexts/NotifyContext';
import 'moment/locale/tr';
import Layout from './components/Layout';
import './styles/globals.css';
import Login from './pages/login';
import { Notification, axiosCMSService, notificationRef } from './services/axios.config';
import UserService from './services/userService';
import AdminPanel from './pages/admin';
import ApprovePages from './pages/approve/Approve';
import LinkShortener from './pages/linkshortener';
import Menu from './pages/menu';
import NewsFormDialog from './pages/news/Dialog';
import MenuFormDialog from './pages/menu/Dialog';
import News from './pages/news/News';
import NewsEditor from './pages/news/NewsEditor';
import Page from './pages/page';
import CreatePage from './pages/page/create';
import PageDetail from './pages/page/detail/detail';
import NewsDialog from './pages/page/detail/news/dialog';
import NewsSection from './pages/page/detail/news/news';
import EditDialog from './pages/page/detail/section/editDialog';
import LinkedDialog from './pages/page/detail/section/linkedDialog';
import SectionSection from './pages/page/detail/section/section';
import AddDialog from './pages/page/detail/slider/addDialog';
import SliderSection from './pages/page/detail/slider/slider';
import UpdateDialog from './pages/page/detail/slider/updateDialog';
import SectionFormDialog from './pages/section/Dialog';
import Section from './pages/section/Section';
import SectionItem from './pages/section/SectionItem';
import UserRole from './pages/userrole/userRole';
import Slider from './pages/slider';
import ResetPassword from './pages/resetpassword';

export const cookies = new Cookies();
moment.locale('tr');

export default function App({ Component, pageProps }) {
  return (
    <ButtonLoaderProvider>
      <DataProvider>
        <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={5000} maxSnack={3}>
          <NotifyProvider>
            <AuthProvider>
              <Notification />
              <AuthContext.Consumer>
                {({ user }) => (
                  <Routes>
                    {!user ? (
                      <>
                        <Route path="/resetpassword/:email/:token" element={<ResetPassword />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="*" element={<Navigate to="/login" replace />} />
                      </>
                    )
                      : (
                        <Route
                          path="*"
                          element={(
                            <Layout user={user}>
                              <Routes>
                                <Route path="/admin" element={<AdminPanel />} />
                                <Route path="/approve/Approve" element={<ApprovePages />} />
                                <Route path="/linkshortener" element={<LinkShortener />} />
                                <Route path="/menu" element={<Menu />} />
                                <Route path="/menu/Dialog" element={<MenuFormDialog />} />
                                <Route path="/page" element={<Page />} />
                                <Route path="/page/create" element={<CreatePage />} />
                                <Route path="/page/detail/detail/:id" element={<PageDetail />} />
                                <Route path="/page/detail/news/dialog" element={<NewsDialog />} />
                                <Route path="/page/detail/news/news" element={<NewsSection />} />
                                <Route path="/page/detail/section/editDialog" element={<EditDialog />} />
                                <Route path="/page/detail/section/linkedDialog" element={<LinkedDialog />} />
                                <Route path="/page/detail/section/section" element={<SectionSection />} />
                                <Route path="/page/detail/slider/addDialog" element={<AddDialog />} />
                                <Route path="/page/detail/slider/slider" element={<SliderSection />} />
                                <Route path="/page/detail/slider/updateDialog" element={<UpdateDialog />} />
                                <Route path="/section/Dialog" element={<SectionFormDialog />} />
                                <Route path="/section/Section" element={<Section />} />
                                <Route path="/section/SectionItem" element={<SectionItem />} />
                                <Route path="/slider" element={<Slider />} />
                                <Route path="/userrole/userRole" element={<UserRole />} />
                                <Route path="*" element={<Navigate to="/page" replace />} />
                              </Routes>
                            </Layout>
                          )}
                        />
                      )}

                  </Routes>
                )}
              </AuthContext.Consumer>
              <AppLoader />
            </AuthProvider>
          </NotifyProvider>
        </SnackbarProvider>
      </DataProvider>
    </ButtonLoaderProvider>
  );
}

const AppLoader = () => {
  const { logout } = useContext(AuthContext);
  const ConfigureAuth = async () => {
    const cookie = cookies.get('user');
    axiosCMSService.defaults.headers.common.Authorization = `Bearer ${cookie?.accessToken}`;
    if (cookie) {
      await UserService.GetAuth(cookie?.accessToken).then((response) => {
        if (response?.data) {
          notificationRef.current?.Show('Oturum süresi dolduğu için çıkış yapılıyor. Lütfen tekrar giriş yapın.', 'error');
          logout();
        }
      });
    } else {
      logout();
    }
  };
  ConfigureAuth();
};

import { Box, Button, Card, Divider, FormControlLabel, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import PageService from '../../../../services/pageService';
import AntSwitch from '../../../../components/Switch';
import NewsService from '../../../../services/newsService';
import NewsDialog from './dialog';
import DeleteDialog from '../../../../components/DeleteDialog';
import { ApprovedStatus } from '../../../../constants/enum';
import LinkedDialog from '../../../../components/LinkedDialog';

function NewsSection(params) {
  const [news, setNews] = useState();
  const [open, setOpen] = useState(false);
  const [linkedOpen, setLinkedOpen] = useState(false);
  const [newsList, setNewsList] = useState();

  const [deleteEvents, setDeleteEvents] = useState({ open: false, itemId: 0 });
  const GetLinkedNews = async (id) => {
    const result = await PageService.GetLinkedNews({ pageId: id, filterApproved: false });
    setNews(result?.data);
  };
  const UnlinkNews = async (pageId, newsId) => {
    await PageService.UnlinkNews({ pageId, unlinkedItemId: newsId }).then(() => {
      setDeleteEvents({ open: false, itemId: 0 });
    });
    GetLinkedNews(params.id);
  };
  const NewsUpdateStatus = async (newsId, status) => {
    await NewsService.UpdateStatus({ newsId, status });
    GetLinkedNews(params.id);
  };
  const getNews = async () => {
    const result = await NewsService.AllList();
    setNewsList(result?.data);
  };

  useEffect(() => {
    getNews();
  }, []);

  useEffect(() => {
    GetLinkedNews(params.id);
  }, [open]);
  return (
    <>
      <Card elevation={10} sx={{ backgroundColor: 'grey.50', padding: 3, borderRadius: '10px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color="text.secondary" variant="h5">
            Haberler ve Duyurular
          </Typography>
          <Box>
            <Button color="warning" variant="text" sx={{ alignItems: 'center' }} onClick={() => setLinkedOpen({ pageId: params.id, status: true })}>
              <AddIcon fontSize="14" />
              <Typography variant="overline">
                Haber ve Duyuru Bağla
              </Typography>
            </Button>
            <Button
              variant="text"
              color="secondary"
              onClick={() => setOpen({ pageId: params.id, pageNews: news, type: 'createNews' })}
              startIcon={<AddIcon style={{ fontSize: 12 }} />}
              style={{ textTransform: 'none' }}
              sx={{ mb: 0, mt: 0, marginRight: 2, fontSize: 12 }}
            >
              Haber veya Duyuru Ekle
            </Button>
          </Box>
        </Box>
        <Grid2 marginTop={2} container spacing={2}>
          {news?.map((x, i) => (
            <>
              <Grid2 item display="flex" lg={12} xs={12} flexWrap="wrap" justifyContent="space-between" alignItems="center" key={x.id}>
                <Typography variant="body2">
                  {x.subject}
                </Typography>
                {x.isApprove === ApprovedStatus.Rejected && (
                  <Typography color="error" variant="body2" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    Reddedilmiş !
                  </Typography>
                )}
                {x.isApprove === ApprovedStatus.Pending && (
                  <Typography color="orange" variant="body2" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    Onay Bekleniyor !
                  </Typography>
                )}
                {x.isApprove === ApprovedStatus.Confirm && (
                  <Typography color="green" variant="body2" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    Onaylanmış.
                  </Typography>
                )}
                <Box flexWrap="wrap" className="sectionInfo" sx={{ display: 'flex', columnGap: 1 }}>
                  <FormControlLabel
                    control={<AntSwitch checked={x.status} onChange={() => NewsUpdateStatus(x.id, !x.status)} />}
                    slotProps={{ typography: { fontSize: 12 } }}
                    label="Durum"
                  />
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => setOpen({ pageId: params.id, pageNews: news, type: 'editNews', data: x })}
                    startIcon={<EditIcon style={{ fontSize: 12 }} />}
                    style={{ textTransform: 'none' }}
                    sx={{ mb: 0, mt: 0, marginRight: 2, fontSize: 12 }}
                  >
                    Düzenle
                  </Button>
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => setDeleteEvents({ open: true, itemId: x.id })}
                    startIcon={<DeleteIcon style={{ fontSize: 12 }} />}
                    style={{ textTransform: 'none' }}
                    sx={{ mb: 0.5, mt: 0.5, mr: 2, fontSize: 12 }}
                  >
                    Bağlantıyı Sil
                  </Button>
                </Box>
              </Grid2>
              {i + 1 !== news.length &&
                <Divider light sx={{ height: 1, width: '100%' }} />
              }
            </>
          ))}
        </Grid2>
      </Card>
      <NewsDialog open={open} setOpen={setOpen} onChange={() => GetLinkedNews(params.id)} />
      <LinkedDialog type="News" header="Haber veya Duyuru Bağla" open={linkedOpen} setOpen={setLinkedOpen} onClose={() => setLinkedOpen({ ...linkedOpen, status: false })} list={newsList} getLinked={() => GetLinkedNews(params.id)} />
      <DeleteDialog
        open={deleteEvents.open}
        onClose={() => setDeleteEvents({ ...deleteEvents, open: false })}
        onConfirm={() => UnlinkNews(params.id, deleteEvents.itemId)}
      />
    </>
  );
}

export default NewsSection;

/* eslint-disable no-mixed-operators */
/* eslint-disable react/jsx-no-constructed-context-values */
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';

const { createContext } = require('react');

const NotifyContext = createContext();

function NotifyProvider({ children }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const enqueue = (message, variant) => enqueueSnackbar(message, {
    variant,
    action: (id) => (
      <Button
        onClick={() => closeSnackbar(id)}
        style={{
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
      />
    ),
  });

  const Notify = (props, message, variant) => {
    if (typeof props === 'string') {
      enqueue(props, message);
    } else if (props?.error) {
      Array.isArray(props.error) ?
        Object.values(props.errors).forEach((element) => {
          enqueue(element.message, 'error');
        })
        :
        enqueue(props.message, 'error');
    } else if (!props?.error && props?.message || message) {
      enqueue((props.message || message), (variant || 'success'));
    }
  };

  return (
    <NotifyContext.Provider value={{ Notify }}>
      {children}
    </NotifyContext.Provider>
  );
}
export { NotifyProvider, NotifyContext };

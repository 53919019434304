import { axiosCMSService } from './axios.config';

const endpoints = {
  create: 'category/create',
  update: 'category/update',
  delete: 'category/delete',
  alllist: 'category/list',
  getbyid: 'category/getbyid',
};

const CategoryService = {
  async Create(data) {
    return axiosCMSService.post(endpoints.create, data);
  },
  async Update(data) {
    return axiosCMSService.post(endpoints.update, data);
  },
  async Delete(data) {
    return axiosCMSService.delete(endpoints.delete, data);
  },
  async Alllist(data) {
    return axiosCMSService.get(endpoints.alllist, data);
  },
  async GetById(data) {
    return axiosCMSService.get(endpoints.getbyid, data);
  },
};

export default CategoryService;

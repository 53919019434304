import { Grid, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { DataContext } from '../../contexts/DataContext';
import { Button } from '../../components/Button';
import { gridLocalize } from '../../utilities/helpers';
import { ApprovedStatus } from '../../constants/enum';
import PageService from '../../services/pageService';
import SectionService from '../../services/sectionService';
import SliderService from '../../services/sliderService';
import NewsService from '../../services/newsService';
import { cookies } from '../../App';

const statusData = [{ value: 'Bekleyen', color: 'primary', status: ApprovedStatus.Pending }, { value: 'Reddilen', color: 'error', status: ApprovedStatus.Rejected }, { value: 'Onaylanan', color: 'success', status: ApprovedStatus.Confirm }];
const itemData = ['İçerikler', 'Slider', 'Sayfalar', 'Haberler', 'Alt İçerikler'];

const getStatusLabel = (status) => {
  switch (status) {
    case ApprovedStatus.Pending:
      return ['Bekliyor', 'warning.main'];
    case ApprovedStatus.Confirm:
      return ['Onaylandı', 'success.main'];
    case ApprovedStatus.Rejected:
      return ['Reddedildi', 'error.main'];
    default:
      return '';
  }
};

const PageKey = [
  { field: 'id', headerName: 'Id', width: 100 },
  { field: 'metaKeyword', headerName: 'Meta Keyword', width: 200 },
  { field: 'metaDescription', headerName: 'Meta Description', width: 200 },
  { field: 'subject', headerName: 'Konu', width: 150 },
  { field: 'createdByUser', headerName: 'Oluşturan Kullanıcı', width: 150 },
  { field: 'createdDate',
    headerName: 'Oluşturulma Tarihi',
    width: 150,
    renderCell: (params) => {
      const splitValue = params.value.split('T');
      const split2 = splitValue[1].split(':');
      return (<Typography variant="body2">{`${splitValue[0]} ${split2[0]}:${split2[1]}`}</Typography>);
    } },
  { field: 'approvedByUser', headerName: 'Durumu Değiştiren', width: 150 },
  { field: 'isApprove', headerName: 'Durum', width: 100, renderCell: (params) => <Typography variant="body2" sx={{ color: getStatusLabel(params.value)[1] }}>{getStatusLabel(params.value)[0]}</Typography> },
];

const SectionKey = [{ field: 'id', headerName: 'Id', width: 100 },
  { field: 'name', headerName: 'İsim', width: 250 },
  { field: 'createdDate',
    headerName: 'Oluşturulma Tarihi',
    width: 150,
    renderCell: (params) => {
      const splitValue = params.value.split('T');
      const split2 = splitValue[1].split(':');
      return (<Typography variant="body2">{`${splitValue[0]} ${split2[0]}:${split2[1]}`}</Typography>);
    } },
  { field: 'createdByUser', headerName: 'Oluşturan Kullanıcı', width: 150 },
  { field: 'approvedByUser', headerName: 'Durumu Değiştiren', width: 150 },
  { field: 'isApprove', headerName: 'Durum', width: 100, renderCell: (params) => <Typography variant="body2" sx={{ color: getStatusLabel(params.value)[1] }}>{getStatusLabel(params.value)[0]}</Typography> },
];
const SectionItemKey = [{ field: 'id', headerName: 'Id', width: 100 },
  { field: 'name', headerName: 'İsim', width: 250 },
  { field: 'sectionName', headerName: 'Menu İsmi', width: 150 },
  { field: 'createdDate',
    headerName: 'Oluşturulma Tarihi',
    width: 150,
    renderCell: (params) => {
      const splitValue = params.value.split('T');
      const split2 = splitValue[1].split(':');
      return (<Typography variant="body2">{`${splitValue[0]} ${split2[0]}:${split2[1]}`}</Typography>);
    } },
  { field: 'createdByUser', headerName: 'Oluşturan Kullanıcı', width: 150 },
  { field: 'approvedByUser', headerName: 'Durumu Değiştiren', width: 150 },
  { field: 'isApprove', headerName: 'Durum', width: 100, renderCell: (params) => <Typography variant="body2" sx={{ color: getStatusLabel(params.value)[1] }}>{getStatusLabel(params.value)[0]}</Typography> },
];
const SliderKey = [{ field: 'id', headerName: 'Id', width: 100 },
  { field: 'title', headerName: 'Başlık', width: 150 },
  { field: 'webImageUrl', headerName: 'Web Fotoğraf Uzantısı', width: 250 },
  { field: 'mobileImageUrl', headerName: 'Mobil Fotoğraf Uzantısı', width: 250 },
  { field: 'createdDate',
    headerName: 'Oluşturulma Tarihi',
    width: 150,
    renderCell: (params) => {
      const splitValue = params.value.split('T');
      const split2 = splitValue[1].split(':');
      return (<Typography variant="body2">{`${splitValue[0]} ${split2[0]}:${split2[1]}`}</Typography>);
    } },
  { field: 'createdByUser', headerName: 'Oluşturan Kullanıcı', width: 150 },
  { field: 'approvedByUser', headerName: 'Durumu Değiştiren', width: 150 },
  { field: 'isApprove', headerName: 'Durum', width: 100, renderCell: (params) => <Typography variant="body2" sx={{ color: getStatusLabel(params.value)[1] }}>{getStatusLabel(params.value)[0]}</Typography> },

];

const NewsKey = [
  { field: 'id', headerName: 'Id', width: 100 },
  { field: 'subject', headerName: 'Konu', width: 200 },
  { field: 'category', headerName: 'Kategori', width: 100 },,
  { field: 'createdDate',
    headerName: 'Oluşturulma Tarihi',
    width: 150,
    renderCell: (params) => {
      const splitValue = params.value.split('T');
      const split2 = splitValue[1].split(':');
      return (<Typography variant="body2">{`${splitValue[0]} ${split2[0]}:${split2[1]}`}</Typography>);
    } },
  { field: 'createdByUser', headerName: 'Oluşturan Kullanıcı', width: 200 },
  { field: 'approvedByUser', headerName: 'Durumu Değiştiren', width: 150 },
  { field: 'isApprove', headerName: 'Durum', width: 100, renderCell: (params) => <Typography variant="body2" sx={{ color: getStatusLabel(params.value)[1] }}>{getStatusLabel(params.value)[0]}</Typography> },

];
function AdminPanel() {
  const [status, setStatus] = React.useState({ value: 'Bekleyen', color: 'primary', status: ApprovedStatus.Pending });
  const [selectItem, setSelectItem] = React.useState('Slider');
  const [checked, setChecked] = React.useState();
  const [sectionItemList, setSectionItemList] = React.useState([]);
  const { approvePageList,
    setApprovePageList,
    approveNewsList,
    setApproveNewsList,
    approveSectionList,
    setApproveSectionList,
    approveSliderList,
    setApproveSliderList } = useContext(DataContext);

  const AprroveListPage = async () => {
    await PageService.GetApproveList().then((response) => {
      setApprovePageList(response?.data);
    });
  };
  const ApproveListSection = async () => {
    await SectionService.GetApproveList().then((response) => {
      setApproveSectionList(response?.data);
    });
  };
  const ApproveListSlider = async () => {
    await SliderService.GetApproveList().then((response) => {
      setApproveSliderList(response?.data);
    });
  };
  const ApproveListNews = async () => {
    await NewsService.GetApproveList().then((response) => {
      setApproveNewsList(response?.data);
    });
  };

  const ApproveListSectionItem = async () => {
    await SectionService.GetApproveListItem().then((response) => {
      setSectionItemList(response?.data);
    });
  };
  const ApproveUpdate = async (itemStatus) => {
    if (selectItem === 'Sayfalar') {
      await PageService.UpdateApprove(itemStatus, checked);
      AprroveListPage();
    }
    if (selectItem === 'Haberler') {
      await NewsService.ApproveUpdate(itemStatus, checked);
      ApproveListNews();
    } else if (selectItem === 'İçerikler') {
      await SectionService.ApproveUpdate(itemStatus, checked);
      ApproveListSection();
    } else if (selectItem === 'Slider') {
      await SliderService.ApproveUpdate(itemStatus, checked);
      ApproveListSlider();
    } else if (selectItem === 'Alt İçerikler') {
      await SectionService.ApproveUpdateItem(itemStatus, checked);
      ApproveListSectionItem();
    }
  };
  useEffect(() => {
    AprroveListPage();
    ApproveListSectionItem();
    ApproveListSection();
    ApproveListNews();
    ApproveListSlider();
  }, []);

  return (
    <Grid container spacing={2}>
      {/* <h1>Upload your files</h1>

      <form action="">
        <input name="file" type="file" />
      </form> */}

      {statusData.map((value, index) => (
        <Grid key={index} item>
          <Button
            onClick={() => setStatus(value)}
            color={value.color}
            sx={{
              height: 80,
              width: 370,
              borderRadius: 5,
              opacity: status.value !== value.value ? 0.6 : 1,
            }}
          >
            <Typography sx={{ textAlign: 'center' }} color="#ffff">{value.value}</Typography>
          </Button>
        </Grid>
      ))}
      {itemData.map((value, index) => (
        <Grid key={index} item>
          <Button
            onClick={() => setSelectItem(value)}
            color="primary"
            sx={{
              height: 30,
              width: 215,
              opacity: selectItem !== value ? 0.6 : 1,
            }}
          >
            <Typography variant="h8" sx={{ textAlign: 'center' }} color="#ffff">{value}</Typography>
          </Button>
        </Grid>
      ))}
      <div style={{ height: 650, width: '100%', marginTop: 20 }}>
        <DataGrid
          onSelectionModelChange={(item) => {
            setChecked(item.map((id) => ({ id })));
          }}
          localeText={{ ...gridLocalize,
            MuiTablePagination: {
              labelRowsPerPage: 'Sayfa Başına Kayıt',
            } }}
          rows={(selectItem === 'Sayfalar' && approvePageList?.filter((f) => f.isApprove === status.status)) || (selectItem === 'İçerikler' && approveSectionList?.filter((f) => f.isApprove === status.status)) ||
          (selectItem === 'Slider' && approveSliderList?.filter((f) => f.isApprove === status.status)) || (selectItem === 'Haberler' && approveNewsList?.filter((f) => f.isApprove === status.status))
          || (selectItem === 'Alt İçerikler' && sectionItemList?.filter((f) => f.isApprove === status.status))}
          columns={(selectItem === 'Sayfalar' && PageKey) || (selectItem === 'İçerikler' && SectionKey) ||
          (selectItem === 'Slider' && SliderKey) || (selectItem === 'Haberler' && NewsKey) || (selectItem === 'Alt İçerikler' && SectionItemKey)}
          pageSize={10}
          pageSizeOptions={[5]}
          checkboxSelection
        />
      </div>
      <Grid sx={{ justifyContent: 'space-around' }} container spacing={2}>
        <Grid item xs={2}>
          <Button
            disableElevation
            style={{ fontSize: 15, marginTop: 40, alignItems: 'center' }}
            variant="contained"
            onClick={() => ApproveUpdate(ApprovedStatus.Confirm)}
          >
            İşlemleri Onayla
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            disableElevation
            style={{ fontSize: 15, marginTop: 40, alignItems: 'center' }}
            variant="contained"
            color="error"
            onClick={() => ApproveUpdate(ApprovedStatus.Rejected)}
          >
            İşlemleri Reddet
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default AdminPanel;

import { axiosCMSService } from './axios.config';

const endpoints = {
  create: 'section/create',
  update: 'section/update',
  approveupdate: 'section/approveupdate',
  delete: 'section/delete',
  deleteItem: 'section/deleteitem',
  alllist: 'section/alllist',
  getsectionitem: 'section/getsectionitem',
  getapprovelist: 'section/getapprovelist',
  getSectionList: 'section/getsectionList',
  createSectionItem: 'section/createItems',
  getbysectionid: 'section/GetBySectionId',
  updatestatus: 'section/updatestatus',
  getapproveitemlistbymenuid: 'section/GetApproveItemListByMenuId',
  approveupdateitem: 'section/ApproveItemUpdate',
  getapproveitemlist: 'section/GetApproveItemList',
  updateitem: 'section/updateitem',
  updateitemstatus: 'section/UpdateItemStatus',
  linkedfile: 'section/linkedfile',
  unlinkedfile: 'section/unlinkedfile',
};

const SectionService = {
  async Create(data) {
    return axiosCMSService.post(endpoints.create, data);
  },
  async CreateSectionItem(data) {
    return axiosCMSService.post(endpoints.createSectionItem, data);
  },
  async Update(data) {
    return axiosCMSService.post(endpoints.update, data);
  },
  async UpdateItem(data) {
    return axiosCMSService.post(endpoints.updateitem, data);
  },
  async UpdateItemStatus(data) {
    return axiosCMSService.post(endpoints.updateitemstatus, data);
  },
  async ApproveUpdate(status, data) {
    return axiosCMSService.post(`${endpoints.approveupdate}?status=${status}`, data);
  },
  async ApproveUpdateItem(status, data) {
    return axiosCMSService.post(`${endpoints.approveupdateitem}?status=${status}`, data);
  },
  async DeleteItem(data) {
    return axiosCMSService.delete(`${endpoints.deleteItem}?id=${data}`);
  },
  async Delete(data) {
    return axiosCMSService.delete(`${endpoints.delete}?id=${data}`);
  },
  async AllList(filterApproved = false) {
    return axiosCMSService.get(`${endpoints.alllist}?filterApproved=${filterApproved}`);
  },
  async GetSectionList(filterApproved = false) {
    return axiosCMSService.get(`${endpoints.getSectionList}?filterApproved=${filterApproved}`);
  },
  async GetById(data) {
    return axiosCMSService.post(endpoints.getsectionitem, data);
  },
  async GetBySectionId(data) {
    return axiosCMSService.post(endpoints.getbysectionid, data);
  },
  async UpdateStatus(data) {
    return axiosCMSService.post(endpoints.updatestatus, data);
  },
  async GetApproveList() {
    return axiosCMSService.get(endpoints.getapprovelist);
  },
  async GetApproveListItemByMenuId(sectionId) {
    return axiosCMSService.get(`${endpoints.getapproveitemlistbymenuid}?sectionId=${sectionId}`);
  },
  async GetApproveListItem() {
    return axiosCMSService.get(endpoints.getapproveitemlist);
  },
  async LinkedFile(data) {
    return axiosCMSService.post(endpoints.linkedfile, data);
  },
  async UnLinkedFile(data) {
    return axiosCMSService.post(endpoints.unlinkedfile, data);
  },
};

export default SectionService;

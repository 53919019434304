import { axiosCMSService } from './axios.config';

const endpoints = {
  login: 'user/login',
  create: 'user/Create',
  forgotPassword: 'user/ForgotPassword',
  changePassword: 'user/ChangePassword',
  resetPassword: 'user/ResetPassword',
  getAuth: 'user/Authenticated',
};

const UserService = {
  async Login(data) {
    return axiosCMSService.post(endpoints.login, data);
  },
  async Create(data) {
    return axiosCMSService.post(endpoints.create, data);
  },
  async ForgotPassword(data) {
    return axiosCMSService.post(endpoints.forgotPassword, data);
  },
  async ChangePassword(data) {
    return axiosCMSService.post(endpoints.changePassword, data);
  },
  async ResetPassword(data) {
    return axiosCMSService.post(endpoints.resetPassword, data);
  },
  async GetAuth(token) {
    return axiosCMSService.get(`${endpoints.getAuth}?token=${token}`);
  },
};
export default UserService;

import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, TextField, Toolbar, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { DataContext } from '../../contexts/DataContext';
import { NotifyContext } from '../../contexts/NotifyContext';
import SectionService from '../../services/sectionService';
import SectionItem from './SectionItem';
import AntSwitch from '../../components/Switch';

const headerLabels = {
  createsection: 'Bölüm Ekle',
  editsection: 'Bölüm Düzenle',
  deletesection: 'Bölüm Sil',
  createsectionitem: 'Bölüm Elemanı Ekle',
  editsectionitem: 'Bölüm Elemanı Düzenle',
  deletesectionitem: 'Bölüm Elemanı Sil',
};
export default function SectionFormDialog({ open, setOpen }) {
  const data = open?.data;
  const [formData, setFormData] = React.useState();
  const { Notify } = React.useContext(NotifyContext);
  const { setSectionList } = useContext(DataContext);
  const getAllSections = async () => {
    const result = await SectionService.AllList();
    setSectionList(result?.data);
    return result;
  };
  useEffect(() => {
    setFormData(data);
  }, [open]);
  function ModalSwitch() {
    async function Create({ name }) {
      if (!name) {
        Notify('Section ismi boş olamaz!', 'error');
      } else {
        await SectionService.Create({ name });
        setOpen();
        getAllSections();
      }
    }
    {
      switch (String(open?.type)) {
        case 'createsectionitem':
          return (
            <DialogContent>
              <SectionItem open={open} tabName={0} onChange={() => setOpen(false)} />
            </DialogContent>
          );
        case 'createsection':
          return (
            <>
              <DialogTitle>Bölüm Ekle</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  value={formData?.name}
                  onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
                  margin="dense"
                  id="name"
                  label="Bölüm Adı"
                  type="text"
                  fullWidth
                  variant="standard"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen()}>İptal</Button>
                <Button onClick={() => Create({ name: formData?.name }).then((response) => { setOpen(); })}>Ekle</Button>
              </DialogActions>
            </>
          );
        case 'editsection':
          return (
            <>
              <DialogContent>
                <TextField
                  autoFocus
                  value={formData?.name}
                  onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
                  margin="dense"
                  id="name"
                  label="Bölüm Adı"
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <FormControlLabel label="Durum" control={<AntSwitch checked={formData?.status} onChange={(e) => setFormData((prev) => ({ ...prev, status: e.target.checked }))} />} />

              </DialogContent>

              <DialogActions>
                <Button onClick={() => setOpen()}>İptal</Button>
                <Button onClick={() => SectionService.Update({ id: formData.id, name: formData?.name, status: formData.status }).then((response) => { setOpen(); getAllSections(); })}>Güncelle</Button>
              </DialogActions>
            </>
          );
        case 'deletesectionitem':
          return (
            <DialogContent>
              <SectionItem open={open} tabName={2} onChange={() => setOpen(false)} />
            </DialogContent>
          );
        case 'editsectionitem':
          return (
            <DialogContent>
              <SectionItem open={open} tabName={1} onChange={(e) => setOpen(e)} />
            </DialogContent>
          );
      }
    }
  }
  const fullWidthDialog = open?.type === ('createsectionitem' || 'editsectionitem');
  return (
    <div>
      {open ? (
        <Dialog disableEnforceFocus fullWidth={fullWidthDialog} maxWidth={fullWidthDialog} open={!!open} onClose={() => setOpen(!open)}>
          <AppBar sx={{ position: 'relative', backgroundColor: open.type.includes('delete') ? 'error.main' : 'primary' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpen()}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {headerLabels[open.type]}
              </Typography>
            </Toolbar>
          </AppBar>
          <ModalSwitch />
        </Dialog>
      ) : null}
    </div>
  );
}

/* eslint-disable react/jsx-indent */
import { Autocomplete, Step, StepButton, Stepper, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Button } from '../../components/Button';
import { NotifyContext } from '../../contexts/NotifyContext';
import MenuService from '../../services/menuService';

const { Box } = require('@mui/system');

let renderer = Math.random();
const steps = ['Sayfa bilgilerini ekle', 'Sayfa bölümlerini ekle'];

function CreatePage() {
  const [activeStep, setActiveStep] = useState(0);
  const [menuList, setMenuList] = useState();
  const { Notify } = useContext(NotifyContext);
  const [completed, setCompleted] = useState({});
  const [data, setData] = useState({
    menuItemId: 0,
    subMenuItemId: 0,
    metaKeyword: '',
    metaDescription: '',
    subject: '',
    body: '',
  });

  const totalSteps = () => steps.length;

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const getMenuList = async () => {
    const result = await MenuService.Alllist();
    setMenuList(result?.data);
    return result;
  };

  useEffect(() => {
    getMenuList();
  }, []);

  return (
    <Box>
      <Stepper sx={{ mb: 6 }} activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      {
        {
          0: <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
            <Autocomplete
              getOptionLabel={(x) => x.name}
              onChange={(event, newValue) => { renderer = Math.random(); setData((x) => ({ ...x, menuItemId: newValue.id, subMenuItemId: undefined })); }}
              options={menuList}
              fullWidth
              renderInput={(params) => <TextField {...params} label="Menü" />}
            />
            <Autocomplete
              getOptionLabel={(x) => x.name}
              onChange={(event, newValue) => setData((x) => ({ ...x, subMenuItemId: newValue.id || '' }))}
              key={renderer}
              fullWidth
              options={menuList?.find((item) => item.id === data?.menuItemId)?.subMenus || []}
              renderInput={(params) => <TextField {...params} label="Alt Menü" />}
            />
            <TextField fullWidth onChange={(e) => setData((x) => ({ ...x, metaKeyword: e.target.value || '' }))} helperText="finance,crypto,forex..." label="Meta Keyword" variant="outlined" />
            <TextField fullWidth onChange={(e) => setData((x) => ({ ...x, metaDescription: e.target.value || '' }))} multiline label="Meta Description" variant="outlined" />
            <TextField fullWidth onChange={(e) => setData((x) => ({ ...x, subject: e.target.value || '' }))} label="Subject" variant="outlined" />
             </Box>,
          1:
  <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
    <Autocomplete
      getOptionLabel={(x) => x.name}
      onChange={(event, newValue) => { renderer = Math.random(); setData((x) => ({ ...x, menuItemId: newValue.id, subMenuItemId: undefined })); }}
      options={menuList}
      fullWidth
      renderInput={(params) => <TextField {...params} label="Menü" />}
    />
    <Autocomplete
      getOptionLabel={(x) => x.name}
      onChange={(event, newValue) => setData((x) => ({ ...x, subMenuItemId: newValue.id || '' }))}
      key={renderer}
      fullWidth
      options={menuList?.find((item) => item.id === data?.menuItemId)?.subMenus || []}
      renderInput={(params) => <TextField {...params} label="Alt Menü" />}
    />
    <TextField fullWidth onChange={(e) => setData((x) => ({ ...x, metaKeyword: e.target.value || '' }))} helperText="finance,crypto,forex..." label="Meta Keyword" variant="outlined" />
    <TextField fullWidth onChange={(e) => setData((x) => ({ ...x, metaDescription: e.target.value || '' }))} multiline label="Meta Description" variant="outlined" />
    <TextField fullWidth onChange={(e) => setData((x) => ({ ...x, subject: e.target.value || '' }))} label="Subject" variant="outlined" />
  </Box>,
        }[activeStep]
      }
      {activeStep === steps.length - 1 ? (
        <Button onClick={handleComplete} sx={{ mr: 1 }}>
          Complete
        </Button>
      ) : (
        <Button onClick={handleNext} sx={{ mr: 1 }}>
          Next
        </Button>
      )}

      <Button
        color="inherit"
        disabled={activeStep === 0}
        onClick={handleBack}
        sx={{ mr: 1 }}
      >
        Back
      </Button>

    </Box>
  );
}

export default CreatePage;
